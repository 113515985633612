import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Prerequisites`}</h2>
    <ul>
      <li parentName="ul">{`A user with access to Engagement Studio;`}</li>
    </ul>
    <h2>{`Overview`}</h2>
    <p>{`File sharing refers to the process of transferring or distributing digital files between devices, users, or systems.`}</p>
    <h2>{`Steps`}</h2>
    <h3>{`1. Add file sharing app`}</h3>
    <ol>
      <li parentName="ol">{`On the side bar of Engagement Studio, click `}<em parentName="li">{`AdCenter`}</em>{` (`}<span parentName="li" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "0",
            "marginRight": "0",
            "maxWidth": "67px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/30c5a123c5bfd4cd675713661d506403/55ef4/ec-adCenter.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "104.4776119402985%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAABYlAAAWJQFJUiTwAAACc0lEQVQ4y41V2XaiQBDNB8w5zmgii4iC24hZVOK+4BKDsiXRZP7/T+6cKoGgiZqHC0139a2qvl3FlZjTcAqCXDwzV/x2z9WpjUmCG1FlCPJl51fnyCKUqwZKVQM/ySYmvBbyyGTz/E5ClIuwZgsGpXm8nskqHH1EyilnpQLuH0wsFitYsyWDxt3eCP3hBIPhBG2zh9HYQqc7xDxhN188oVa/Zw6OkNhLFQMvr2+8qdXuMtpml50MR1OsVjam1gLjiYVmq4OW2WXQuNcfwQ9eIef1fYQUtnHbxOrZRiotxmkQorS00l8U9Cr+XMvxfEb4tHFdn9cpSiasNx7wbK9xI+YhKXp80Dm1BFnR+YzUYhXGbQt6uR6nR6B11/OhapVPQiMkvBaU2JDmN47HKf/OSNg4Lm/0/YDTjISQctpXwnqCUAojonPdbndwXA+iXGDyUqWOXF5H8PLK62QnKz8gTGdzrJ7jeHjb7nD3YGL59IxytY5fqSzstQPjrsnX7CIhKVXQa9judphac9j2huF6AczHHvqDMTw/iFWVLhHSZKXWQPuxx6oKUgGd7gDDsYX1xsVsvkRRr8XCXDjDvcrkmRZpTKAxQ1TZZh+ZHqqs8bU5UrnJaZGaB2UlKBz1cbkdl53nBSho4T2kB334wQunM5nOMJnOw/cpzBlUOU8rG+u1w3V+UMt0JaicqMR2u3e02h2YnT7Mx0PQ2dKZfnz8Y4GoTNUwuoNuQxOpjIRytcFeo/TpahyChFM5KlI6fZOLW1ccYbInyvkSezzX++iqkE2yTON+uM9dO/AShX/cfI9tkt/7bh5G+N0/5GsnL551Eq3/B45MXNHI/hT6AAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/30c5a123c5bfd4cd675713661d506403/381f8/ec-adCenter.webp 67w"],
                "sizes": "(max-width: 67px) 100vw, 67px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/30c5a123c5bfd4cd675713661d506403/55ef4/ec-adCenter.png 67w"],
                "sizes": "(max-width: 67px) 100vw, 67px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/30c5a123c5bfd4cd675713661d506403/55ef4/ec-adCenter.png",
                "alt": "icon-adCenter",
                "title": "icon-adCenter",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`).`}</li>
      <li parentName="ol">{`Click `}<em parentName="li">{`Apps`}</em>{` on the menu.`}</li>
      <li parentName="ol">{`Click `}<em parentName="li">{`Add`}</em>{` to insert an app.`}</li>
      <li parentName="ol">{`Select `}<em parentName="li">{`App - Cobrowsing (Live support)`}</em>{`.`}</li>
      <li parentName="ol">{`Fill in the `}<em parentName="li">{`Name`}</em>{` and select `}<em parentName="li">{`Yes`}</em>{` to activate.`}
        <ul parentName="li">
          <li parentName="ul"><em parentName="li">{`Name`}</em>{`: It is required  and it is free text but you should give a meaningful name.`}</li>
          <li parentName="ul"><em parentName="li">{`Active`}</em>{`: `}<em parentName="li">{`Yes`}</em>{` or `}<em parentName="li">{`No`}</em></li>
        </ul>
      </li>
      <li parentName="ol">{`On the right bottom corner of form `}<em parentName="li">{`Create App`}</em>{`, click button `}<em parentName="li">{`Accept`}</em>{`.`}</li>
      <li parentName="ol">{`The edit app is displayed. `}
        <br />![ec-edit-filesharing](../images/ec-edit-filesharing.png)
      </li>
      <li parentName="ol">{`Click `}<em parentName="li">{`Add files`}</em>{`, to add the files that can be shared with the visitors.`}
        <br />![ec-add-file](../images/ec-add-file.png)
      </li>
      <li parentName="ol">{`Click `}<em parentName="li">{`Select file`}</em>{`.`}</li>
      <li parentName="ol">{`Select the file on finder.`}</li>
      <li parentName="ol">{`Click `}<em parentName="li">{`Send`}</em>{`.`}
        <br />![ec-file-selected](../images/ec-file-selected.png)
      </li>
    </ol>
    <h4>{`Result`}</h4>
    <p>{`The files are ready to be shared.`}</p>
    <blockquote>
      <p parentName="blockquote">{`When changing a setting, on the right top corner of form `}<em parentName="p">{`Edit app`}</em>{`, don't forget to click button `}<em parentName="p">{`Save`}</em>{` to save the settings. `}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Note: In Contact Center & Analytics Studio is possible to define for each operator if this functionality is available or not. `}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      